import React from "react"

export default function Template() {
  
  return (
    <>
      <link rel="stylesheet" href="/policy.css"/>
      <div id="content">
        <section className="row">
          <div className="col-full">
          <p>
            <h1 style={{fontSize: "36px"}}><p>개인정보처리방침</p></h1>
            <p>&nbsp;</p>
            <h2>슈퍼매직 기업사이트 개인정보처리방침</h2>
            <p>슈퍼매직(이하 회사)는 이용자의 소중한 개인정보를 보호하기 위해 개인정보보호 관련 법령을 준수합니다.<br />개인정보처리방침을 통해 회사가 어떤 개인정보를 수집하고, 어떻게 이용하며, 누구에게 제공하는지, 언제 어떻게 파기하는지 등에 대해 이해할 수 있으며 본 개인정보처리방침은 회사 홈페이지(http://www.supermagic.io/) 및 인재채용, 광고/사업 문의 관련 제반 서비스에 적용됩니다. 변경 사항이 발생하는 경우 개인정보처리방침을 통해 안내하겠습니다.</p>
            <p>개인정보 보호를 위해 어떠한 조치를 취하고 있는지 다음과 같이 알려드립니다.</p>
            <p><strong>1. 처리하는 개인정보의 항목</strong><br />본 사이트는 별도의 회원 가입 절차 없이 컨텐츠에 자유롭게 접근할 수 있습니다. 다만, 회사는 채용, 제휴 제안, 투자상담, 채용문의, 기타문의 (홍보, 사회공헌, 고객의견 등), 서비스 제공을 위해 아래와 같이 개인 정보를 처리하고 있습니다.</p>
            <p><strong>1.1 수집 항목</strong><br /><strong>1) 채용 관련 개인 정보</strong><br /><strong>[개발]</strong><br />- 필수정보 : 지원자, 지원분야, 연봉, 입사가능일자, 졸업구분, 재학기간, 학위구분, 고용형태, 회사명, 근무기간, 부서, 담당업무<br />- 선택정보 : 추천인, 지원경로, 고등학교, 대학교, 입학구분, 학과/전공, 경험 및 경력기술서, 직급, 퇴직사유, 포트폴리오<br /><strong>[비개발]</strong><br />- 필수정보 : 추천인, 지원경로, 고등학교, 대학교, 입학구분, 학과/전공, 경험 및 경력기술서, 직급, 퇴직사유, 포트폴리오, 자기소개서<br />- 선택정보 : 추천인, 지원경로, 고등학교, 대학교, 입학구분, 학과/전공, 경험 및 경력기술서, 직급, 퇴직사유, 포트폴리오, 자격증, 컴퓨터활용능력, 해외경험, 외국어활용능력<br /><strong>2) 제휴 제안, 투자상담, 채용 문의, 기타문의(홍보, 사회공헌, 고객의견 등) 관련 개인정보</strong><br />- 필수정보 : 고객명/회사명, E-Mail, 문의내용, 첨부파일<br />- 선택정보 : 연락처<br /><strong>3) 홈페이지 접속에 따른 자동 수집 및 생성정보</strong><br />- IP, 접속기록, 쿠키(Cookie)<br /><strong>2.2 수집 방법</strong><br />- 회사는 이용자의 개인정보를 회사 홈페이지 채용시스템 내 지원자 정보 입력 페이지(임시저장 및 최종제출을 한 경우) 또는 서면 입사지원서를 통해 직접 또는 간접적(헤드헌팅 등)으로 수집합니다.<br />- 제안, 투자상담, 채용문의, 기타문의(홍보, 사회공헌, 고객의견 등) 등에 필요한 이용자의 개인정보는 문의 페이지 또는 각 담당 부서의 공식 문의 메일주소, 별도의 동의서 등을 통해 수집합니다.</p>
            <p><strong>2. 개인정보의 처리 목적</strong><br />회사는 수집한 개인정보를 다음과 같은 목적을 위해 처리합니다.<br />1) 채용 관련 개인정보 : 온라인 입사 지원자의 인적사항, 이력 확인( 합격여부, 재지원 여부 등) 및 서류 전형 또는 면접 전형의 심사자료, 자격요건 확인에 따른 채용 확인, 면접일정 통지 등 지원자와의 원활한 의사 소통 경로 확보, 향후 채용 가능 자원의 관리 목적으로 처리합니다.<br />2) 제휴 제안, 투자상담, 채용문의, 기타문의(홍보, 사회공헌, 고객의견 등)관련 개인정보 : 문의 및 요청 처리, 서비스 제공을 위한 목적으로 처리합니다.<br />3) 수집한 개인정보는 상기 목적 이외의 용도로는 사용되지 않으며, 처리 목적이 변경될 시에는 이용자(정보주체)의 사전 동의를 구할 예정입니다.</p>
            <p><strong>3. 개인정보의 처리 및 보유기간</strong><br />1) 채용관련 개인정보<br />- 회사는 입사지원자가 회사에 제공하는 개인정보는 입력일로부터 1년 간 보유한 후 기록을 재생할 수 없는 기술적 방법으로 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우에만 예외로 합니다.<br />- 입사지원자가 개인정보의 삭제를 원하는 경우 지체 없이 해당 정보를 삭제합니다.<br />2) 제휴 제안, 투자상담, 채용문의, 기타문의(홍보, 사회공헌, 고객의견 등) 관련 개인정보<br />- 보존근거 : 문의 응대를 위한 보관<br />- 보존기간 : 문의하기 등록일로부터 6개월<br />3) 그 밖에 이용자에게 미리 고지하거나 개별 동의한 기간 동안 보관합니다.</p>
            <p><strong>4. 개인정보처리의 위탁</strong><br />회사가 개인정보 처리를 위탁하는 경우 개인정보 보호의 안전을 기하기 위하여 위탁업무의 수행목적 외 개인정보 처리금지, 기술적/관리적 보호조치, 수탁자에 대한 관리/감독, 손해배상, 재위탁 제한, 처리 종료 후의 개인정보의 반환 또는 파기 등을 계약서 등 문서에 명확히 규정하고, 수탁자가 개인정보를 안전하게 처리하는지 감독하게 됩니다. 회사는 원활한 업무 처리를 위해 아래와 같이 외부 전문업체에 개인정보를 위탁하고 있습니다.</p>
            <p>- ㈜마이다스 : 지원서 작성 서비스</p>
            <p><strong>5. 개인정보의 제3자 제공</strong><br />1) 회사는 개인정보를 "2. 개인정보의 처리 목적"에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 제공하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다.<br />다만, 아래의 경우에는 예외로 합니다.<br />- 이용자들이 사전에 공개에 동의한 경우<br />- 기타 법에 의해 요구된다고 선의로 판단되는 경우 (예. 관련법령에 의거 적법한 절차에 의한 정부/수사기관의 요청이 있는 경우 등)<br />2) 입사지원자에게 회사 및 계열사와 특수관계에 있는 아래 회사에 입사제안을 드릴 수 있으며, 이 경우 반드시 사전에 입사지원자에게 동의 절차를 거친 후 입사지원서를 해당 회사로 제공합니다.<br />- 제공받는 자 : ㈜ 111퍼센트, ㈜ZZOO, ㈜슈퍼센트, ㈜리치에일리언 <br />- 제공 목적 : ㈜슈퍼매직의 관계사 채용 시 입사지원자 정보 공유 및 입사전형 진행<br />- 제공 항목 : 1항의 처리하는 개인정보 항목과 동일<br />- 보유 및 이용기간 : 3항의 처리 및 보유기간과 동일<br />※ 제3자 제공에 동의하지 않은 경우, 입사지원자의 개인정보는 채용에 지원한(인재 POOL은 지원분야에 해당하는) 회사 또는 계열사에서만 수집 및 이용합니다.</p>
            <p><strong>6. 개인정보의 파기</strong><br />회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. 개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
            <p><strong>1) 파기절차</strong><br />- 이용자가 입력한 정보는 목적이 달성된 후 지체없이 파기합니다. 만약 5항에 따라 별도의 보유기간에 대해 동의 받았거나 관계법령에 의해 보관이 필요한 경우, 동의 받은 보유 및 이용기간에 따라 일정 기간 저장된 후 파기합니다.<br /><strong>2) 파기방법</strong><br />- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br />- 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</p>
            <p><strong>7. 이용자와 법정대리인의 권리․의무 및 그 행사방법</strong><br />1) 이용자 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 삭제를 요청할 수 있습니다. 또한 회사의 개인정보 보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하도록 하겠습니다.<br />2) 이용자가 개인정보의 오류에 대한 정정을 요청한 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 회사는 이용자 혹은 법정대리인의 요청에 의해 해지 또는 삭제된 개인정보는 &ldquo;개인정보의 처리 및 보유기간&rdquo;에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.<br />3) 다만, 회사는 다음 각 호의 1에 해당하는 경우에는 개인정보의 전부 또는 일부에 대하여 열람 또는 정정 등을 거절할 수 있습니다.<br />- 본인 또는 제3자의 생명, 신체, 재산 또는 권익을 현저하게 해할 우려가 있는 경우<br />- 회사의 업무에 현저한 지장을 미칠 우려가 있는 경우<br />- 법령에 위반하는 경우</p>
            <p><strong>8. 개인정보의 자동 수집 장치의 설치, 운영 및 그 거부에 관한 사항</strong><br />1) 회사는 이용자에게 특화된 개인화되고 맞춤화 된 서비스를 제공하기 위해 이용자의 개인정보를 저장하고 수시로 찾아내는 쿠키(cookie, 접속정보파일)를 운용합니다. 쿠키는 웹사이트 서버가 이용자의 브라우저 또는 앱에 보내는 소량의 정보(텍스트 파일)로서 이용자의 컴퓨터 등 디바이스에 저장됩니다. 회사는 쿠키 운용과 관련하여 이용자의 컴퓨터 및 휴대폰은 식별하지만 이용자를 개인적으로 식별하지는 않습니다.<br />2) 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 회원은 웹 브라우저에서 옵션을 선택하거나, 모바일 기기 설정 또는 옵션에서 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때 마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수 있습니다. 단 모든 쿠키의 저장을 거부하는 경우, 쿠키를 통해 회사에서 제공하는 서비스를 이용할 수 없습니다.<br /><strong>[브라우저 설정 방법]</strong><br />- Internet Explorer : 웹 브라우저 상단의 도구 메뉴 &gt; 인터넷 옵션 &gt; 개인정보 &gt; 설정<br />- Chrome : 웹 브라우저 우측의 설정 메뉴 &gt; 화면 하단의 고급 설정 표시 &gt; 개인정보 및 보안 &gt; 쿠키 및 기타 사이트 데이터<br />3) 웹사이트 상에서 분석 도구인 Google Analytics를 사용할 수 있습니다. Google 웹 로그 분석에서 쿠키를 사용하는 방법에 대한 자세한 내용은 www.google.com/policies/privacy/partners/에서 확인할 수 있습니다. 또한, http://tools.google.com/dlpage/gaoptout를 통해 모든 웹사이트에서 Google Analytics의 사용을 중지 할 수도 있습니다.</p>
            <p><strong>9. 개인정보의 안전성 확보조치</strong><br />1) 개인정보 처리 직원의 최소화 및 교육 : 개인정보를 처리하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.<br />2) 내부관리계획의 수립 및 시행 : 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br />3) 개인정보의 암호화 : 이용자의 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.<br />4) 해킹 등에 대비한 기술적 대책 : 회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신&bull;점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.<br />5) 개인정보에 대한 접근 제한 : 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br />6) 접속기록의 보관 및 위변조 방지 : 개인정보처리시스템에 접속한 기록을 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.<br />7) 문서보안을 위한 잠금 장치 사용 : 개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고 있습니다.</p>
            <p><strong>10. 개인정보 보호책임자 및 개인정보의 열람청구 접수&middot;처리 부서, 권익침해에 대한 구제방법</strong><br />1) 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자 및 개인정보 열람청구 접수&middot;처리 부서를 지정하고 있습니다.<br /><strong>[개인정보 보호책임자]</strong><br />- 이름 : 이종화<br />- 소속 : 정보보안본부<br />- 이메일: help@supermagic.io<br />- 연락처 : 070-4633-4939</p>
            <p><strong>[개인정보 열람청구 접수&middot;처리 부서]</strong><br />- 부서명 : 운영본부<br />- 연락처 : help@supermagic.io<br />2) 이용자는 회사의 서비스(또는 사업)을 이용하면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의할 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.<br />3) 기타 개인정보침해에 대한 피해구제, 상담 등이 필요하신 경우에는 아래기관에 문의하시기 바랍니다.<br />아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.<br />- 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)<br />- 개인정보 분쟁조정위원회 (www.kopico.go.kr / 국번없이 1833-6972)<br />- 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)<br />- 경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번없이 182)</p>
            <p><strong>11. 개인정보처리방침의 변경</strong><br />회사는 정부의 정책 또는 보안 기술의 변경에 따라 개인정보처리방침의 추가, 삭제 및 수정이 있을 때에는 개정 최소 7일전부터 홈페이지를 통해 고지하며 이 중 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경은 최소 30일 전에 고지합니다. 다만, 사전 고지하기로 한 일자 내 고지가 곤란한 경우에는 지체 없이 고지합니다.</p>
            <p><strong>* 공고일자 : 2022년 02월 23일 </strong><br /><strong>* 시행일자 : 2022년 02월 23일</strong></p>
        </p>
          </div>
        </section>
        <section class="row">
          <div class="col-full">
            <p>© 2022. Supermagic Inc.</p>
          </div>
        </section>
      </div>
    </>
  )
}